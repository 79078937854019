// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getAnalytics, logEvent } from "firebase/analytics";
// import { doc } from "firebase/firestore";
// TODO: Add SDKs for Firebase products that you want to use
// https://firebase.google.com/docs/web/setup#available-libraries

// Your web app's Firebase configuration
// For Firebase JS SDK v7.20.0 and later, measurementId is optional
const firebaseConfig = {
    apiKey: "AIzaSyBTtdtMDp3OM8Dn_ZGAOAdvDnwkbZlMV8w",
    authDomain: "tic-tac-toe-fd0fa.firebaseapp.com",
    projectId: "tic-tac-toe-fd0fa",
    storageBucket: "tic-tac-toe-fd0fa.appspot.com",
    messagingSenderId: "777863999958",
    appId: "1:777863999958:web:8eac4e0545870f3fdab477",
    measurementId: "G-PK7S5C7JBP"
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
const analytics = getAnalytics(app);

var board = Array(3).fill().map(() => Array(3).fill(''));
var currentPlayer = 'X';
var scores = { 'X': -1, 'O': 1, 'tie': 0 };
var gameOver = false;
var maxDepth = 3; // Default maxDepth
var isCalculating = false;
let startTime = null;
let storedDifficulty = null;
var playerTurn = 'X';
let playerScore = 0;
let aiScore = 0;

function setup() {
    // Set the playerTurn to the default value
    localStorage.setItem('playerTurn', playerTurn);

    // Check game difficulty in local storage
    storedDifficulty = localStorage.getItem('difficulty');

    if (storedDifficulty) {
        // Set the game difficulty based on the value in local storage
        setDifficulty(storedDifficulty);
    } else {
        // Set a default difficulty level if difficulty hasn't been set yet
        var defaultDifficulty = "medium";
        setDifficulty(defaultDifficulty);
        localStorage.setItem('difficulty', defaultDifficulty);
        // Update the current difficulty level
        storedDifficulty = defaultDifficulty;
    }

    // Check game mode in local storage
    var gameMode = localStorage.getItem('gameMode');
    if (gameMode === null) {
        // Set the default game mode to 'one-player'
        gameMode = 'one-player';
    }

    var boardElement = document.getElementById('board');
    for (var i = 0; i < 3; i++) {
        var row = document.createElement('div');
        row.className = 'row';
        for (var j = 0; j < 3; j++) {
            var cell = document.createElement('div');
            cell.className = 'cell';

            // Modify the cell click event listener
            cell.addEventListener('click', (function (i, j) {
                return function () {
                    if (gameOver || isCalculating) return; // Prevent clicking if the game is over or the AI is calculating
                    if (board[i][j] == '') {
                        // Start timing when the first move is made
                        if (startTime === null) {
                            startTime = Date.now();
                            // Update the storedDifficulty from localStorage when the first move is made
                            storedDifficulty = localStorage.getItem('difficulty');
                        }

                        board[i][j] = currentPlayer;
                        if (currentPlayer == 'X') {
                            var x = document.createElement('div');
                            x.className = 'x';
                            this.appendChild(x);
                        } else {
                            this.innerText = currentPlayer;
                        }
                        currentPlayer = currentPlayer == 'X' ? 'O' : 'X';
                        if (gameMode === 'one-player' && currentPlayer == 'O') {
                            isCalculating = true; // Start calculating
                            var bestMove = minimax(board, 0, true, maxDepth);
                            if (bestMove.i !== null && bestMove.j !== null) {
                                board[bestMove.i][bestMove.j] = 'O';
                                var cell = document.querySelector(`.row:nth-child(${bestMove.i + 1}) .cell:nth-child(${bestMove.j + 1})`);
                                var circle = document.createElement('div');
                                circle.className = 'circle';
                                cell.appendChild(circle);
                                // Delay the switch of currentPlayer to 'X' by 0.5 seconds
                                setTimeout(function () {
                                    currentPlayer = 'X';
                                    isCalculating = false; // Done calculating
                                }, 400);
                            }
                        }
                        updateAnnouncement();
                        // Switch the playerTurn to the other player after a game is over
                        if (gameOver) {
                            playerTurn = playerTurn === 'X' ? 'O' : 'X';
                            localStorage.setItem('playerTurn', playerTurn);
                        }
                    }
                };
            })(i, j));
            row.appendChild(cell);
        }
        boardElement.appendChild(row);
    }

    // If it's the AI's turn to go first, make the AI's move
    if (playerTurn === 'O' && gameMode === 'one-player') {
        isCalculating = true; // Start calculating
        var bestMove = minimax(board, 0, true, maxDepth);
        if (bestMove.i !== null && bestMove.j !== null) {
            board[bestMove.i][bestMove.j] = 'O';
            var cell = document.querySelector(`.row:nth-child(${bestMove.i + 1}) .cell:nth-child(${bestMove.j + 1})`);
            var circle = document.createElement('div');
            circle.className = 'circle';
            cell.appendChild(circle);
            // Delay the switch of currentPlayer to 'X' by 0.5 seconds
            setTimeout(function () {
                currentPlayer = 'X';
                isCalculating = false; // Done calculating
            }, 500);
        }
    }
}

function minimax(board, depth, isMaximizing, maxDepth) {
    var winner = checkWin();
    if (winner != null) {
        return { score: scores[winner], i: null, j: null };
    } else if (depth == maxDepth) {
        return { score: 0, i: null, j: null };
    }

    var bestMoves = [];
    var bestScore = isMaximizing ? -Infinity : Infinity;

    for (var i = 0; i < 3; i++) {
        for (var j = 0; j < 3; j++) {
            if (board[i][j] == '') {
                board[i][j] = isMaximizing ? 'O' : 'X';
                var result = minimax(board, depth + 1, !isMaximizing, maxDepth);
                board[i][j] = '';

                if ((isMaximizing && result.score > bestScore) || (!isMaximizing && result.score < bestScore)) {
                    bestScore = result.score;
                    bestMoves = [{ i, j, score: bestScore }];
                } else if (result.score === bestScore) {
                    bestMoves.push({ i, j, score: bestScore });
                }
            }
        }
    }

    // Randomly pick a move from the list of best moves
    if (bestMoves.length > 0) {
        var randomIndex = Math.floor(Math.random() * bestMoves.length);
        return bestMoves[randomIndex];
    }

    return isMaximizing ? { score: -Infinity, i: null, j: null } : { score: Infinity, i: null, j: null };
}

function checkWin() {
    for (var i = 0; i < 3; i++) {
        if (board[i][0] !== '' && board[i][0] === board[i][1] && board[i][0] === board[i][2]) {
            return board[i][0];
        }
    }

    for (var j = 0; j < 3; j++) {
        if (board[0][j] !== '' && board[0][j] === board[1][j] && board[0][j] === board[2][j]) {
            return board[0][j];
        }
    }

    if (board[0][0] !== '' && board[0][0] === board[1][1] && board[0][0] === board[2][2]) {
        return board[0][0];
    }
    if (board[0][2] !== '' && board[0][2] === board[1][1] && board[0][2] === board[2][0]) {
        return board[0][2];
    }

    var isTie = true;
    for (var i = 0; i < 3; i++) {
        for (var j = 0; j < 3; j++) {
            if (board[i][j] === '') {
                isTie = false;
                break;
            }
        }
        if (!isTie) {
            break;
        }
    }
    if (isTie) {
        return 'tie';
    }

    return null;
}

function updateAnnouncement() {
    var announcement = document.getElementById('announcement');
    var result = checkWin();
    if (result === 'X' || result === 'O') {
        gameOver = true; // Game is over

        announcement.innerText = result + ' wins!';

        var won = result === 'X' ? 'player' : 'AI';

        // Update scores
        if (won === 'player') {
            playerScore++;
        } else {
            aiScore++;
        }

        // Update the scores in the UI
        document.getElementById('human-score').textContent = playerScore;
        document.getElementById('bot-score').textContent = aiScore;

        // Calculate game duration in different formats
        var endTime = Date.now();
        var totalMillis = endTime - startTime;
        var totalSeconds = totalMillis / 1000;
        var hours = Math.floor(totalSeconds / 3600);
        var minutes = Math.floor((totalSeconds % 3600) / 60);
        var seconds = Math.floor((totalSeconds % 60));
        var millis = totalMillis % 1000;

        // Log a "game_over" event with the winner and game duration
        logEvent(analytics, 'game_over', {
            winner: won,
            game_duration_milliseconds: totalMillis,
            game_duration_seconds: totalSeconds,
            game_duration_time: `${hours}h ${minutes}m ${seconds}s ${millis}ms`,
            final_board_state: JSON.stringify(board),
            difficulty: storedDifficulty
        });

        // Reset the startTime for the next game
        startTime = null;

        // Possible winning combinations
        const winningCombinations = [
            [[0, 0], [0, 1], [0, 2]], // first row
            [[1, 0], [1, 1], [1, 2]], // second row
            [[2, 0], [2, 1], [2, 2]], // third row
            [[0, 0], [1, 0], [2, 0]], // first column
            [[0, 1], [1, 1], [2, 1]], // second column
            [[0, 2], [1, 2], [2, 2]], // third column
            [[0, 0], [1, 1], [2, 2]], // main diagonal
            [[0, 2], [1, 1], [2, 0]]  // secondary diagonal
        ];

        let winningCells = [];

        // Check each winning combination
        for (let combination of winningCombinations) {
            const [a, b, c] = combination;
            if (board[a[0]][a[1]] && board[a[0]][a[1]] === board[b[0]][b[1]] && board[a[0]][a[1]] === board[c[0]][c[1]]) {
                // This is a winning combination
                winningCells = combination;
                break;
            }
        }

        // Get the winning divs inside the winning cells
        const winningDivs = winningCells.map(cell => document.querySelector(`.row:nth-child(${cell[0] + 1}) .cell:nth-child(${cell[1] + 1})`).firstChild);

        // Delay the start of the win animation
        setTimeout(() => {
            winningDivs.forEach((div, index) => {
                // Calculate the animation delay for each div
                let animationDelay = index * 1; // Changed from 2s to 1s

                div.style.animationDelay = `${animationDelay}s`;

                // Set the animation duration to be equal to the total duration plus the pause
                div.style.animationDuration = `${winningDivs.length + 0.5}s`;

                div.classList.add('win');
            });
        }, 300); // Delay for 1 second
    } else if (result === 'tie') {
        gameOver = true; // Game is over

        announcement.innerText = 'It\'s a tie!';

        // Calculate game duration in different formats
        var endTime = Date.now();
        var totalMillis = endTime - startTime;
        var totalSeconds = totalMillis / 1000;
        var hours = Math.floor(totalSeconds / 3600);
        var minutes = Math.floor((totalSeconds % 3600) / 60);
        var seconds = Math.floor((totalSeconds % 60));
        var millis = totalMillis % 1000;

        // Log a "game_over" event with the winner and game duration
        logEvent(analytics, 'game_over', {
            winner: won,
            game_duration_milliseconds: totalMillis,
            game_duration_seconds: totalSeconds,
            game_duration_time: `${hours}h ${minutes}m ${seconds}s ${millis}ms`,
            final_board_state: JSON.stringify(board),
            difficulty: storedDifficulty
        });

        // Reset the startTime for the next game
        startTime = null;
    }
}

function updateLine(line, startCell, endCell) {
    var dx = endCell.offsetLeft + endCell.offsetWidth / 2 - startCell.offsetLeft - startCell.offsetWidth / 2;
    var dy = endCell.offsetTop + endCell.offsetHeight / 2 - startCell.offsetTop - startCell.offsetHeight / 2;
    var angle = Math.atan2(dy, dx);
    var startLeft = startCell.offsetLeft + startCell.offsetWidth / 2;
    var startTop = startCell.offsetTop + startCell.offsetHeight / 2;
    var endLeft = endCell.offsetLeft + endCell.offsetWidth / 2;
    var endTop = endCell.offsetTop + endCell.offsetHeight / 2;

    // Adjust the starting point and ending point of the line based on the angle
    if (angle === 0) { // The line is horizontal
        startLeft = startCell.offsetLeft;
        endLeft = endCell.offsetLeft + endCell.offsetWidth;
    } else if (angle === Math.PI / 2 || angle === -Math.PI / 2) { // The line is vertical
        startTop = startCell.offsetTop;
        endTop = endCell.offsetTop + endCell.offsetHeight;
    } else if (angle > 0) { // The line is diagonal and goes from top left to bottom right
        startLeft = startCell.offsetLeft;
        startTop = startCell.offsetTop;
        endLeft = endCell.offsetLeft + endCell.offsetWidth;
        endTop = endCell.offsetTop + endCell.offsetHeight;
    } else { // The line is diagonal and goes from top right to bottom left
        startLeft = startCell.offsetLeft + startCell.offsetWidth;
        startTop = startCell.offsetTop;
        endLeft = endCell.offsetLeft;
        endTop = endCell.offsetTop + endCell.offsetHeight;
    }

    dx = endLeft - startLeft;
    dy = endTop - startTop;
    var lineWidth = Math.sqrt(Math.pow(dx, 2) + Math.pow(dy, 2));

    line.style.top = startTop + 'px';
    line.style.left = startLeft + 'px';
    line.style.transform = 'rotate(' + angle + 'rad)';
    line.style.setProperty('--line-width', lineWidth + 'px'); // Set the --line-width variable
}

function resetBoard() {
    board = Array(3).fill().map(() => Array(3).fill(''));
    currentPlayer = localStorage.getItem('playerTurn') || 'X';
    gameOver = false;
    isCalculating = false;

    // Check game mode in local storage
    var gameMode = localStorage.getItem('gameMode');
    if (gameMode === null) {
        // Set the default game mode to 'one-player'
        gameMode = 'one-player';
    }

    // Check game difficulty in local storage
    var difficulty = localStorage.getItem('difficulty');
    if (difficulty !== null) {
        // Set the game difficulty based on the value in local storage
        setDifficulty(difficulty);

        // Update the current difficulty level
        storedDifficulty = difficulty;
    }

    for (var i = 0; i < 3; i++) {
        for (var j = 0; j < 3; j++) {
            var cell = document.querySelector(`.row:nth-child(${i + 1}) .cell:nth-child(${j + 1})`);
            while (cell.firstChild) {
                cell.removeChild(cell.firstChild);
            }
        }
    }

    var winner = document.getElementById('winner');
    if (winner !== null) {
        winner.innerText = '';
    }

    var announcement = document.getElementById('announcement');
    announcement.innerText = '';

    // If it's the AI's turn to go first, make the AI's move
    if (currentPlayer === 'O' && gameMode === 'one-player') {
        isCalculating = true; // Start calculating
        var bestMove = minimax(board, 0, true, maxDepth);
        if (bestMove.i !== null && bestMove.j !== null) {
            board[bestMove.i][bestMove.j] = 'O';
            var cell = document.querySelector(`.row:nth-child(${bestMove.i + 1}) .cell:nth-child(${bestMove.j + 1})`);
            var circle = document.createElement('div');
            circle.className = 'circle';
            cell.appendChild(circle);
            // Delay the switch of currentPlayer to 'X' by 0.5 seconds
            setTimeout(function () {
                currentPlayer = 'X';
                isCalculating = false; // Done calculating
            }, 500);
        }
    }
}

function setDifficulty(difficulty) {
    switch (difficulty) {
        case 'easy':
            maxDepth = 2;
            break;
        case 'medium':
            maxDepth = 4;
            break;
        case 'hard':
            maxDepth = 6;
            break;
        case 'impossible':
            maxDepth = 9;
            break;
        default:
            console.error('Invalid difficulty level');
    }
}

function updateDifficulty() {
    // Check if any moves have been made
    for (var i = 0; i < 3; i++) {
        for (var j = 0; j < 3; j++) {
            if (board[i][j] !== '') {
                // If any moves have been made, return early without changing the difficulty
                return;
            }
        }
    }

    var difficulty = document.querySelector('input[name="difficulty"]:checked').value;
    setDifficulty(difficulty);
    localStorage.setItem('difficulty', difficulty);

    // Update the current difficulty level
    window.currentDifficulty = difficulty;
}

var newGameButton = document.getElementById('new-game');

// Add a click event listener to the button
newGameButton.addEventListener('click', function () {
    // Log a "new_game" event to Firebase Analytics when the button is clicked
    logEvent(analytics, 'new_game');
    resetBoard();
});

window.onload = setup;
